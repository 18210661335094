import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash-es';
import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import RadioButtonGroup from '../components/ui/RadioButtonGroup';
import RadioButton from '../components/ui/RadioButton';
import StepperLayout from '../components/layouts/StepperLayout';
import {
  getAvailableDistributorsLS,
  getSelectedDistributorLS,
  getUserType,
  setCurrentPlan,
  setCurrentRetailer,
  setSelectedDistributorLS,
} from '../utils/localStorage/localStorageFunctions';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const DistributorChoice = () => {
  const [availableDistributors] = useState(
    () => getAvailableDistributorsLS()?.distributors
  );

  const [initialFormValues] = useState(() => {
    const distributor = getSelectedDistributorLS();
    return { distributor: distributor?.id || '' };
  });

  const handleNext = (values: typeof initialFormValues) => {
    if (!availableDistributors) return;
    const selectedDistributor = availableDistributors.find(
      (distributor) => distributor?.id === values.distributor
    );

    if (!selectedDistributor) return;

    if (!isEqual(selectedDistributor, getSelectedDistributorLS())) {
      setCurrentRetailer(null);
      setCurrentPlan(null);
    }

    setSelectedDistributorLS(selectedDistributor);

    navigateAndSetActiveStep(StepUrl.URL_BILL_TYPE);
  };

  const handleBack = () => {
    const userType = getUserType();

    if (userType === 'business') {
      navigateAndSetActiveStep(StepUrl.URL_POSTCODE_BUSINESS);
      return;
    }

    navigateAndSetActiveStep(StepUrl.URL_POSTCODE_HOUSEHOLD);
  };

  usePreloadPageData(
    StepUrl.URL_BILL_TYPE,
    StepUrl.URL_POSTCODE_BUSINESS,
    StepUrl.URL_POSTCODE_HOUSEHOLD
  );

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={1}
        currentStep={2}
        pageUrl={StepUrl.URL_DISTRIBUTOR_CHOICE}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
          >
            {(props) => (
              <Form>
                <h2 className="my-2">Who is your distributor?</h2>
                <p className="sub-heading">
                  There are multiple distributors in your postcode
                </p>
                <RadioButtonGroup className="my-2">
                  {availableDistributors?.map(
                    (distributor) =>
                      distributor && (
                        <RadioButton
                          key={distributor.id}
                          value={distributor.id}
                          name="distributor"
                          id={distributor.id}
                          required
                        >
                          {distributor?.name}
                        </RadioButton>
                      )
                  )}
                </RadioButtonGroup>
                <Button
                  className="mt-5"
                  type="submit"
                  disabled={props.values.distributor === ''}
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <BackButton text="Back" onClick={handleBack} />
      </StepperLayout>
    </SEOLayout>
  );
};

export default DistributorChoice;
